import React, { createContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return { ...state, user: action.payload.user, token: action.payload.token, zendesk: action.payload.zendesk, tokenMessage: action.payload.tokenMessage, loading: false };
        case 'SET_ACCESOS':
            return { ...state, accesos: action.payload };
        case 'LOGOUT':
            return { ...state, user: null, token: null, accesos: null, loading: false };
        case 'ERROR':
            return { ...state, error: action.payload, loading: false };
        case 'SET_LOADING':
            return { ...state, loading: true };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const storedToken = localStorage.getItem('REG1');
    const storedUser = JSON.parse(localStorage.getItem('REG2'));
    const storedAccesos = JSON.parse(localStorage.getItem('REG3'));
    const storedZendesk = localStorage.getItem('REG4');
    const storedMessage = localStorage.getItem('REG5');

    const [state, dispatch] = useReducer(reducer, {
        user: storedUser || null,
        token: storedToken || null,
        accesos: storedAccesos || null,
        zendesk: storedZendesk || null,
        message: storedMessage || null,
        loading: false,
        error: null
    });

    const navigate = useNavigate();

    useEffect(() => {
        validateToken();
    }, []);

    const login = async (username, password) => {
        dispatch({ type: 'SET_LOADING' });
        try {
            const response = await axios.post(
                'https://apiregatas2.regataslima.pe/api/login',
                { username, password },
                {
                    headers: { 'x-api-key': '$2a$10$Qlv0anBHMFaIGTq8CExl9.iIyBUv3ERhTPaP5G8OrXsXE4uPELVmm' }
                }
            );
            const { token, usuario, zendesk, tokenMessage } = response.data;
            if (usuario.role === 'COLABORADOR_ROLE' || usuario.role === 'ADMIN_ROLE' || usuario.role === 'SUPER_ADMIN_ROLE') {
                localStorage.setItem('REG1', token);
                localStorage.setItem('REG2', JSON.stringify(usuario));
                localStorage.setItem('REG4', zendesk);
                localStorage.setItem('REG5', tokenMessage);
                dispatch({ type: 'LOGIN_SUCCESS', payload: { user: usuario, token, zendesk, tokenMessage } });
                await fetchAccesos(usuario._id, token);
                navigate('/');
            } else if (usuario.role === 'TITULAR_ROLE' || usuario.role === 'FAMILIAR_ROLE') {
                localStorage.setItem('REG1', token);
                localStorage.setItem('REG2', JSON.stringify(usuario));
                localStorage.setItem('REG4', zendesk);
                localStorage.setItem('REG5', tokenMessage);
                dispatch({ type: 'LOGIN_SUCCESS', payload: { user: usuario, token, zendesk, tokenMessage } });
                navigate('/');
            } else {
                // Redirigir a la página de acceso si el rol no está autorizado
                navigate('/access');
                throw new Error('Rol no autorizado');
            }
        } catch (error) {
            dispatch({ type: 'ERROR', payload: error.response.data.message });
        }
    };

    const validateToken = async () => {
        const oldtoken = localStorage.getItem('REG1');
        if (!oldtoken) {
            dispatch({ type: 'LOGOUT' });
            return;
        }

        dispatch({ type: 'SET_LOADING' });
        try {
            const response = await axios.get('https://apiregatas2.regataslima.pe/api/renew-user', {
                headers: { 'x-token': oldtoken }
            });
            const { token, usuario, zendesk, tokenMessage } = response.data;
            if (usuario.role === 'COLABORADOR_ROLE' || usuario.role === 'ADMIN_ROLE' || usuario.role === 'SUPER_ADMIN_ROLE') {
                localStorage.setItem('REG1', token);
                localStorage.setItem('REG2', JSON.stringify(usuario));
                localStorage.setItem('REG4', zendesk);
                localStorage.setItem('REG5', tokenMessage);
                dispatch({ type: 'LOGIN_SUCCESS', payload: { user: usuario, token, zendesk, tokenMessage } });
                await fetchAccesos(usuario._id, token);
            } else if (usuario.role === 'TITULAR_ROLE' || usuario.role === 'FAMILIAR_ROLE') {
                localStorage.setItem('REG1', token);
                localStorage.setItem('REG2', JSON.stringify(usuario));
                localStorage.setItem('REG4', zendesk);
                localStorage.setItem('REG5', tokenMessage);
                dispatch({ type: 'LOGIN_SUCCESS', payload: { user: usuario, token, zendesk, tokenMessage } });
            } else {
                // Redirigir a la página de acceso si el rol no está autorizado
                navigate('/access');
                throw new Error('Rol no autorizado');
            }
        } catch (error) {
            dispatch({ type: 'LOGOUT' });
        }
    };

    const fetchAccesos = async (userId, token) => {
        try {
            const response = await axios.get(`https://apiregatas2.regataslima.pe/api/acceso/${userId}`, {
                headers: { 'x-token': token }
            });
            localStorage.setItem('REG3', JSON.stringify(response.data));
            dispatch({ type: 'SET_ACCESOS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'ERROR', payload: error.response.data.message });
        }
    };

    const logout = () => {
        localStorage.removeItem('REG1');
        localStorage.removeItem('REG2');
        localStorage.removeItem('REG3');
        localStorage.removeItem('REG4');
        localStorage.removeItem('REG5');
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
    };

    return <AuthContext.Provider value={{ ...state, login, logout, validateToken }}>{children}</AuthContext.Provider>;
};
